body {
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-family: "Open Sans", sans-serif !important;
}

.main-wrp {
  width: 100%;
  height: 100%;
  display: flex;
}

.main-wrp .logo-holder {
  position: absolute;
  left: 50%;
  width: 260px;
  height: 260px;
  border-radius: 50%;
  background-color: white;
  margin-left: -130px;
  top: 50%;
  margin-top: -130px;
}

.main-wrp .logo-holder img {
  max-width: 60%;
  position: absolute;
  left: 50%;
  margin-left: -80px;
  top: 50%;
  margin-top: -50px;
}

.main-wrp .rs,
.main-wrp .ls {
  flex: 1;
}

.main-wrp .rs,
.main-wrp .ls {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.main-wrp .ls {
  background-color: #ef5a29;
  padding-right: 200px;
}

.main-wrp .ls .ls-ts {
  padding-top: 30px;
}

.main-wrp .ls .hlf.txt-wrp,
.main-wrp .trd.txt-wrp {
  display: flex;
  align-items: center;
}

.main-wrp .ls .ls-ms .ic-wrp span {
  display: block;
}

.main-wrp .trd.txt-wrp h2 {
  font-size: 26px;
  color: #ffffff;
  padding-right: 60px;
}

.main-wrp .rs {
  background-color: #006497;
  padding-top: 30px;
  padding-left: 200px;
}

.main-wrp .mobile-links {
  display: none;
}

.main-wrp .trd.btn-wrp a,
.main-wrp .mobile-links a {
  background-color: #3097D1;
  border: 0;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 600;
  padding: 15px;
  text-transform: uppercase;
  width: 100px;
  text-align: center;
}

.main-wrp .trd,
.main-wrp .hlf {
  flex: 2;
}

.main-wrp .hlf.txt-wrp {
  flex: 1;
}

.main-wrp .hlf .row .right-align {
  text-align: right;
  padding: 0;
}

.main-wrp .hlf .row .right-align span {
  color: #ffffff;
  font-size: 26px;
}

.main-wrp .hlf.txt-wrp .row .col-md-3 img {
  max-width: 100%;
}

.main-wrp .hlf.img-wrp .round-box {
  width: 172px;
  height: 172px;
  border-radius: 50%;
  background-color: #006497;
  float: right;
  text-align: center;
  padding-top: 40px;

}

.main-wrp .hlf.img-wrp .round-box span {
  display: block;
  color: #ffffff;
  font-size: 26px;
  font-weight: 400;
}

.main-wrp .rs .rs-ms,
.main-wrp .ls .ls-ms {
  display: flex;
  flex-direction: column;
}

.main-wrp .rs .rs-ms .ic-wrp,
.main-wrp .ls .ls-ms {
  align-items: flex-end;
}

.main-wrp .ls .ls-ms .ic-wrp {
  flex: 1;
  margin: 75px 0;
}

.main-wrp .rs .rs-ms .ic-wrp {
  margin: 30px 0;
}

.main-wrp .rs .rs-ms {
  margin-top: 70px;
}

.main-wrp .ls .ls-ms .ic-wrp img {
  margin-bottom: 20px;
}

.main-wrp .ls .ls-ms .ic-wrp {
  text-align: center;
}

.main-wrp .ls .ls-ms .ic-wrp span {
  color: #ffffff;

}

@media only screen and (max-width: 1610px) {
  .main-wrp .trd.txt-wrp h2 {
    padding-right: 10px;
  }
}

@media only screen and (max-width: 1441px) {
  .main-wrp .trd.txt-wrp h2 {
    font-size: 18px;
  }
  .main-wrp .hlf .row .right-align span {
    font-size: 18px;
  }
  .main-wrp .ls .ls-ms .ic-wrp {
    margin: 60px 0;
  }
  .main-wrp .hlf.img-wrp .round-box span {
    font-size: 18px;
  }
}

@media only screen and (max-width: 1368px) {
  .main-wrp .trd.txt-wrp h2 {
    font-size: 18px;
    padding-right: 50px;
  }
  .main-wrp .trd.img-wrp img {
    max-width: 70%;
  }
  .main-wrp .trd.btn-wrp {
    padding-right: 30px;
  }
  .main-wrp .hlf .row .right-align span {
    font-size: 18px;
  }
  .main-wrp .rs .rs-ms .ic-wrp {
    margin: 10px 0;
  }
  .main-wrp .ls .ls-ms .ic-wrp {
    margin: 20px 0;
  }
  .main-wrp .rs .rs-ms {
    margin-top: 20px;
  }
  .main-wrp .hlf.img-wrp .round-box {
    width: 136px;
    height: 136px;
  }
  .main-wrp .ls {
    padding-right: 160px;
  }
  .main-wrp .rs {
    padding-left: 160px;
  }
  .main-wrp .trd.txt-wrp h2 {
    padding-right: 10px;
  }
  .main-wrp .ls .hlf .cnt-details .col-md-7 img {
    max-width: 40%;
  }
  .main-wrp .trd.btn-wrp a {
    display: block;
    margin: 10px 0;
  }

}

@media only screen and (max-width: 1280px) {
  .main-wrp .trd.txt-wrp h2 {
    padding-right: 10px;
  }
  .main-wrp .trd.btn-wrp a {
    display: block;
    margin: 20px 0;
  }
  .main-wrp .hlf .row .right-align:nth-of-type(2) {
    padding-top: 15px;
  }
}

@media only screen and (max-width: 480px) {
  body, html {
    overflow: hidden !important;
  }
  .main-wrp {
    flex-direction: column;
  }
  .main-wrp .mobile-links {
    position: absolute;
    display: block;
    top: 110px;
    left: 50%;
    margin-left: -85px;
  }
  .main-wrp .logo-holder {
    position: absolute;
    left: 50%;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-color: white;
    margin-left: -75px;
    top: 50px;
    margin-top: -130px;
  }
  .main-wrp .logo-holder img {
    max-width: 40%;
    position: absolute;
    left: 50%;
    margin-left: -30px;
    top: 80%;
    margin-top: -32px;
  }
  .main-wrp .ls {
    background-color: #006497;
  }
  .main-wrp .hlf.img-wrp .round-box {
    width: 100px;
    height: 100px;
    float: right;
    margin-right: 50px;
    background-color: #ef5a29;
    padding-top: 24px;
    font-size: 14px;
  }
  .main-wrp .hlf .row .right-align:nth-of-type(2) {
    padding-top: 0;
  }
  .main-wrp .hlf .row .right-align {
    text-align: left;
  }
  .main-wrp .ls .hlf .cnt-details .col-md-7 img {
    max-width: 27%;
    position: absolute;
    right: 82px;
    top: -12px;
  }
  .main-wrp .hlf .row .right-align span {
    font-size: 14px;
  }
  .main-wrp .hlf {
    flex: 1;
  }
  .main-wrp .ls .ls-ms .ic-wrp img {
    max-width: 50%;
  }
  .main-wrp .hlf.img-wrp .round-box span {
    font-size: 14px;
  }
  .main-wrp .ls .ls-ms .ic-wrp {
    text-align: center;
  }
  .main-wrp .ls .ls-ms .ic-wrp span {
    font-size: 12px;
  }
  .main-wrp .ls .ls-ms {
    flex-direction: row
  }
  .main-wrp .ls .wrapper {
    display: inherit;
    width: 100%;
  }
  .main-wrp .ls .ls-ms {
    padding: 0 20px;
  }
  .main-wrp .ls .ls-ts {
    padding-top: 280px;
  }
  .main-wrp .ls {
    padding-right: 0;
  }
  .main-wrp .rs {
    position: absolute;
    background-color: transparent;
    padding-left: 30px;
    pointer-events: none;
    top: 0;
  }
  .main-wrp .trd.txt-wrp h2 {
    padding-right: 0;
    font-size: 16px;
  }
  .main-wrp .trd.btn-wrp {
    display: none;
  }
  .main-wrp .rs .rs-ms .ic-wrp img {
    max-width: 70%;
  }
  .main-wrp .trd.img-wrp img {
    max-width: 60%;
  }
  .main-wrp .trd.txt-wrp {
    flex: 1;
    margin: 0 30px;
  }
  .main-wrp .trd.btn-wrp a {
    display: inline-block;
    margin: 20px 35px;
  }
  .main-wrp .rs .rs-ts {
    padding-top: 145px;
  }
  .main-wrp .rs .rs-ms {
    margin-top: 250px;
    display: block;
  }
  .main-wrp .rs .rs-ms .ic-wrp {
    margin: 0;
    float: left;
    width: 24%;
  }
  .main-wrp .trd, .main-wrp .hlf {
    flex: 1;
  }
}

@media only screen and (max-width: 361px) {
  .main-wrp .trd.btn-wrp a {
    margin: 20px 20px;
  }
  .main-wrp .ls .hlf .cnt-details .col-md-7 img {
    right: 60px;
  }
}
